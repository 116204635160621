import React, { useEffect, useState } from "react";
import { Dropdown, SearchBarHome, Shimmer } from "~/components";
import { useNavigate } from "react-router-dom";

import { AnalyticsBarChart, AnalyticsPieChart, DetectionAlerts, MachineStatus } from "./components";

import { getSearchAsync } from "~/store/slice/camera";
import { useSites } from "./hooks";
import { Notification } from "~/svg";

const Sites = () => {
  const {
    datas: { isLoading, countIncident, statusIndicator },
    methods: { goToChi1, goToChi2, goToChi3, goToChi4, goToChi5, goToChi6 },
  } = useSites();

  return (
    <div>
      {isLoading ? (
        <div className="w-full min-h-screen mt-10 px-8">
          <div className="grid grid-cols-3 gap-16">
            <Shimmer rounded={false} height={150} />
            <Shimmer rounded={false} height={150} />
            <Shimmer rounded={false} height={150} />
          </div>
        </div>
      ) : (
        <div className="w-full min-h-screen mt-10 px-8">
          <div className="grid grid-cols-3 gap-16">
            <div
              onClick={goToChi1}
              className="cursor-pointer grid grid-cols-1 gap-6 place-items-center"
            >
              <img className="w-[480px] h-[360px]" src="public/boon_keng.jpg" />
              <div className="flex flex-row gap-2">
                {statusIndicator.length &&
                  statusIndicator
                    .find((s) => s.site === 1)
                    .machines.map((m, midx) => (
                      <MachineStatus key={midx} machine={m.number} status={m.status} />
                    ))}
              </div>
              <div className="flex flex-row items-center w-full justify-center gap-3">
                <p className="text-white text-center text-2xl font-bold">Boon Keng</p>
                <div className="relative">
                  <div className="cursor-pointer">
                    <Notification />
                  </div>
                  {countIncident[0] !== 0 ? (
                    <div className="cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600">
                      <p className="text-[9px] font-semibold text-neutral-50">{countIncident[0]}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={goToChi2}
              className="cursor-pointer grid grid-cols-1 gap-6 place-items-center"
            >
              <img className="w-[480px] h-[360px]" src="public/eunos.jpg" />
              <div className="flex flex-row gap-2">
                {statusIndicator.length &&
                  statusIndicator
                    .find((s) => s.site === 2)
                    .machines.map((m, midx) => (
                      <MachineStatus key={midx} machine={m.number} status={m.status} />
                    ))}
              </div>
              <div className="flex flex-row items-center w-full justify-center gap-3">
                <p className="text-white text-center text-2xl font-bold">Eunos</p>
                <div className="relative">
                  <div className="cursor-pointer">
                    <Notification />
                  </div>
                  {countIncident[1] !== 0 ? (
                    <div className="cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600">
                      <p className="text-[9px] font-semibold text-neutral-50">{countIncident[1]}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={goToChi3}
              className="cursor-pointer grid grid-cols-1 gap-6 place-items-center"
            >
              <img className="w-[480px] h-[360px]" src="public/kallang.jpg" />
              <div className="flex flex-row gap-2">
                {statusIndicator.length &&
                  statusIndicator
                    .find((s) => s.site === 3)
                    .machines.map((m, midx) => (
                      <MachineStatus key={midx} machine={m.number} status={m.status} />
                    ))}
              </div>
              <div className="flex flex-row items-center w-full justify-center gap-3">
                <p className="text-white text-center text-2xl font-bold">Kallang</p>
                <div className="relative">
                  <div className="cursor-pointer">
                    <Notification />
                  </div>
                  {countIncident[2] !== 0 ? (
                    <div className="cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600">
                      <p className="text-[9px] font-semibold text-neutral-50">{countIncident[2]}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={goToChi5}
              className="cursor-pointer grid grid-cols-1 gap-6 place-items-center"
            >
              <img className="w-[480px] h-[360px]" src="public/amk.jpg" />
              <div className="flex flex-row gap-2">
                {statusIndicator.length &&
                  statusIndicator
                    .find((s) => s.site === 5)
                    .machines.map((m, midx) => (
                      <MachineStatus key={midx} machine={m.number} status={m.status} />
                    ))}
              </div>
              <div className="flex flex-row items-center w-full justify-center gap-3">
                <p className="text-white text-center text-2xl font-bold">Ang Mo Kio</p>
                <div className="relative">
                  <div className="cursor-pointer">
                    <Notification />
                  </div>
                  {countIncident[4] !== 0 ? (
                    <div className="cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600">
                      <p className="text-[9px] font-semibold text-neutral-50">{countIncident[4]}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={goToChi4}
              className="cursor-pointer grid grid-cols-1 gap-6 place-items-center"
            >
              <img className="w-[480px] h-[360px]" src="public/marymount.jpg" />
              <div className="flex flex-row gap-2">
                {statusIndicator.length &&
                  statusIndicator
                    .find((s) => s.site === 4)
                    .machines.map((m, midx) => (
                      <MachineStatus key={midx} machine={m.number} status={m.status} />
                    ))}
              </div>
              <div className="flex flex-row items-center w-full justify-center gap-3">
                <p className="text-white text-center text-2xl font-bold">Marymount</p>
                <div className="relative">
                  <div className="cursor-pointer">
                    <Notification />
                  </div>
                  {countIncident[3] !== 0 ? (
                    <div className="cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600">
                      <p className="text-[9px] font-semibold text-neutral-50">{countIncident[3]}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={goToChi6}
              className="cursor-pointer grid grid-cols-1 gap-6 place-items-center"
            >
              <img className="w-[480px] h-[360px]" src="public/tai_seng.jpg" />
              <div className="flex flex-row gap-2">
                {statusIndicator.length &&
                  statusIndicator
                    .find((s) => s.site === 6)
                    .machines.map((m, midx) => (
                      <MachineStatus key={midx} machine={m.number} status={m.status} />
                    ))}
              </div>
              <div className="flex flex-row items-center w-full justify-center gap-3">
                <p className="text-white text-center text-2xl font-bold">Little Road</p>
                <div className="relative">
                  <div className="cursor-pointer">
                    <Notification />
                  </div>
                  {countIncident[5] !== 0 ? (
                    <div className="cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600">
                      <p className="text-[9px] font-semibold text-neutral-50">{countIncident[5]}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sites;
