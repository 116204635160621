export const PENDING_ALERTS = {
  MAJOR_FIRE: "Fire/Smoke",
  WALL_CRACK: "WALL_CRACK",
  FALL_DETECTION: "Fall",
  PEOPLE_LOITERING: "loitering",
};

export const STATUS = {
  PENDING: "PENDING",
  SCHEDULED: "SCHEDULED",
  RESOLVED: "RESOLVED",
  NOTIFIED: "NOTIFIED",
};

export const CAM_STATUS = {
  ERROR: "ERROR",
  LOADING: "LOADING",
  WARNING: "WARNING",
  OK: "OK",
};

export const NOTIF_STATUS = {
  ALL: "All",
  SEEN: "Seen",
  UNREAD: "Unread",
};

export const NOTIF_TYPE = {
  ALERTS: "alert",
  REPORT: "report",
  SEARCH: "search",
};

export const SORT = {
  BEST_MATCH: "Best match",
  LEAST_MATCH: "Least match",
  RECENT: "Recent",
  OLDEST: "Oldest",
};

export const SEARCHTYPE = {
  CCTV: "cctv",
  VIDEO: "video",
};

export const SEARCH_INCIDENT = {
  DESCRIPTION: "Description",
  IMAGE: "Image",
  BOTH: "Both",
};

export const INCIDENT_TYPE = {
  FIRE: "Fire/Smoke",
  LOITERING: "loitering",
  FALL: "Fall",
};

export const EXPORT_TYPE = {
  CSV: "csv",
  IMAGE: "image",
};

export const ANALYTICS_TYPE = {
  CROWD_DENSITY: "Crowd Density",
  FIRE_SMOKE: "Fire/Smoke",
  FALL_DETECTION: "Fall Detection",
  PEOPLE_LOITERING: "People Loitering",
};
